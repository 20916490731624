<template>
  <div class="translationIndex">
    <h1 class="h_none">专利翻译</h1>
    <h2 class="h_none">蓝灯鱼翻译,专利翻译,机器翻译,在线翻译</h2>
    <img src="/images/result_show_logo.png" alt="" class="h_none">
    <!-- <headers></headers> -->
    <!--    <el-alert-->
    <!--      class="alert"-->
    <!--      title="温馨提示："-->
    <!--      type="warning"-->
    <!--      center-->
    <!--      description="尊敬的客户，因公司所在大厦将于4月27日晚7点至28日早6点，进行配电设备检修，届时会影响“专利PDF文本翻译”功能的使用，其他功能不受影响。特此告知，由此给您带来的不便，敬请谅解。"-->
    <!--      show-icon>-->
    <!--    </el-alert>-->
    <input id="copy_content" type="text" value="" style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"/>
    <div class="trans_tab">
      <div :class="['trans_tab_cen',{'active1': fileTran == 1, 'active2':fileTran == 2}]">
        <div @click="fileTran = 0">在线翻译</div>
        <div @click="fileTran = 1">
          <span class="dot">{{count1}}</span>
          文件翻译
        </div>
        <div @click="fileTran = 2">
          <span class="dot">{{count2}}</span>
          OA翻译
        </div>
      </div>
    </div>

    <div v-if="fileTran == 0" class="main-box">
      <div class="status-box">
        <!-- <span @click="serviceNameType == 2 ? toApplyEvent() :''" :class="{active:serviceNameType == 2}">{{serviceNameType === 0 ? $t(`personalCenter.server.try`):serviceNameType === 1 ? $t(`personalCenter.server.apply`):serviceNameType === 2 ? $t(`personalCenter.server.toApplyAbbr`) : ''}}</span> -->
      </div>
      <div :class="['main_box_left',{'focusActived':focusActived}]">
        <div v-if="isCN" class="btn-container fr" @click="translateEvent">{{$t(`patentTranslation.translateBtn`)}}</div>
        <div v-else style="background: transparent" class="btn-container fr" @click="translateEvent"><img
          :src="$t(`patentTranslation.translateBtn`)" alt=""></div>
        <div class="box-top box-item dib-vam-fz0">
          <div class="box-item-left dib-vam-fz0">
            <ul class="dib-vam-fz0">
              <li v-for="(item,index) in $t(`patentTranslation.leftLanguage`)" class="language-item cp" :key="index"
                  :class="{active:item.isActive}" @click="leftChange(item,index)">{{item.text}}
              </li>
            </ul>
            <!--            <div class="uploadClass">-->
            <!--              <el-upload class="upload_btn" action="" :show-file-list="false" :on-change="uploadChangeFn"-->
            <!--                         :auto-upload="false">-->
            <!--                <el-popover placement="bottom" trigger="hover" :content="$t(`patentTranslation.bottom_hover`)"-->
            <!--                            popper-class="translate_popper">-->
            <!--                  <div class="upload-box" slot="reference">-->
            <!--                    <img class="upload" src="@/assets/images/translation/upload_2.png" alt=""-->
            <!--                         @click="leftText='';rightText=''">-->
            <!--                    <img class="upload_hover" src="@/assets/images/translation/upload_2_hover.png" alt=""-->
            <!--                         @click="leftText='';rightText=''">-->
            <!--                  </div>-->
            <!--                </el-popover>-->
            <!--              </el-upload>-->
            <!--            </div>-->
          </div>
        </div>
        <!--        翻译超过3000字提示-->
        <div class="overflow-tips dib-vam-fz0" v-show="countC>3000 && transType != 2">
          <i class="el-icon-warning"></i>
          <span>{{$t(`patentTranslation.errorTips`)}}</span>
        </div>
        <div class="box-body box-item" :class="{'overflow-style':countC>3000}">
          <div class="box-item-left">
            <p class="source" v-show="transType == 2">{{$t(`patentTranslation.title`)}}:{{docText}} <i
              class="el-icon-close" @click="deleteText"></i></p>
            <el-input type="textarea" :autosize="{ minRows: 11}" v-model="leftText" resize="none" class="inner"
                      :class="{shorter:docName}"
                      id="symptomTxt" :placeholder="transType == 1 ? $t(`patentTranslation.placeholder`) : ''"
                      @input="entering"
                      @paste.native="pasteFn" :autofocus="true" ref="textarea" @focus="focusActived = true;"
                      @blur="focusActived = false;"></el-input>
            <i class="el-icon-close" @click="deleteText" v-show="countC && transType == 1"></i>
          </div>
        </div>
        <div class="box-body-bottom">
          <p class="source_language" v-if="languageIdenticalBool">{{$t(`patentTranslation.source_language`)}}<span
            v-for="(item,index) in $t(`patentTranslation.discernLan`)" :key="index" v-show="item.isHow"
            @click="discernEvent(item,index)">{{item.text}}</span></p>
          <p v-if="!languageIdenticalBool"></p>
          <p class="count" :class="{'bt46':countC>3000}"><span style="color: @blue;">{{countC}}</span>/3000</p>
        </div>
      </div>
      <div class="main_box_right">
        <!-- 进度条 -->
        <div class="progressResult" v-show="translatingingBool">
          <div class="loading-outer">
            <p class="loading-inner" :style="{ width: progressResult + '%' }"></p>
          </div>
          <p class="text">{{progressResult + '%'}}</p>

        </div>
        <div class="box-top box-item dib-vam-fz0">
          <div style="width: 100%" class="box-item-right dib-vam-fz0">
            <ul class="dib-vam-fz0">
              <li v-for="(item,index) in $t(`patentTranslation.rightLanguage`)" :key="index" class="language-item cp"
                  :class="{'active':item.isActive,'disable':item.isDisable}"
                  @click="item.isDisable ? '' : rightChange(item,index)">
                {{item.text}}
              </li>
            </ul>
            <div style="margin-top: 16px;float: right;margin-right: 100px;cursor: pointer;width: 20px;height: 20px"
                 class="btn-container fr">
              <img style="width: 100%;height: 100%" v-if="rightText" @click.stop="copy(rightText)"
                   src="../../assets/images/translation/copy.png" alt="">
              <!--              <el-button style="line-height: 0.6" class="el-white2blue" >-->
              <!--                {{$t(`patentTranslation.downloadFn`)}}-->
              <!--              </el-button>-->
            </div>
            <div style="margin-top: 16px;float: right;margin-right: 20px;cursor: pointer" class="btn-container fr">
              <img v-if="rightText" @click.stop="downloadFn" src="../../assets/images/translation/loads.png" alt="">
              <!--              <el-button style="line-height: 0.6" class="el-white2blue" >-->
              <!--                {{$t(`patentTranslation.downloadFn`)}}-->
              <!--              </el-button>-->
            </div>

          </div>
        </div>
        <div class="box-body box-item" :class="{'overflow-style':countC>3000}">
          <div class="box-item-right">
            <p class="source" v-show="transType == 2 && rightText != ''">{{$t(`patentTranslation.origin`)}}</p>
            <!--white-space: pre-wrap;为了保留换行空格-->
            <p class="result inner" :class="{shorter:docName}" v-html="rightText" style="white-space: pre-wrap;"></p>
          </div>
          <img class="ai" src="../../assets/images/translation/ai.png" alt="">
        </div>
      </div>
    </div>

    <div v-else-if="fileTran == 1" class="main-box">

      <div class="status-box">
        <!-- <span @click="serviceNameType == 2 ? toApplyEvent() :''" :class="{active:serviceNameType == 2}">{{serviceNameType === 0 ? $t(`personalCenter.server.try`):serviceNameType === 1 ? $t(`personalCenter.server.apply`):serviceNameType === 2 ? $t(`personalCenter.server.toApplyAbbr`) : ''}}</span> -->
      </div>
      <div :class="['main_box_left',{'focusActived':focusActived}]">
        <el-tabs style="margin-top: 5px;margin-left: 8px" v-model="languageDir" @tab-click="handleClick">
          <el-tab-pane label="中英翻译" name="zh-cn_en-us"></el-tab-pane>
          <el-tab-pane label="英中翻译" name="en-us_zh-cn"></el-tab-pane>
          <el-tab-pane label="中日翻译" name="zh-cn_ja-jp"></el-tab-pane>
          <el-tab-pane label="日中翻译" name="ja-jp_zh-cn"></el-tab-pane>
        </el-tabs>
        <div class="uploads">
          <img src="../../assets/images/translation/upload.png" alt="">
          <div class="item1">支持格式为docx、doc、pdf、txt文件</div>
          <div class="item2">上限2w字</div>
          <div @click="uploadFile" class="item3">上传文件</div>
        </div>

        <el-upload
          class="upload-demo"
          action=""
          ref="upload"
          :show-file-list="false"
          :on-change="uploadChangeFns"
          :auto-upload="false">
        </el-upload>
      </div>
      <div class="main_box_rights">
        <!--        <ul v-if="fileDirFlag" class="trinList">-->
        <!--          <li @click="fileDir(0)" :class="[{'active': item === 0}]">-->
        <!--            <span>中</span>-->
        <!--            <img src="../../assets/images/translation/switch.png" alt="">-->
        <!--            <span>英</span>-->
        <!--          </li>-->
        <!--          <li @click="fileDir(1)" :class="[{'active': item === 1}]">-->
        <!--            <span>英</span>-->
        <!--            <img src="../../assets/images/translation/switch.png" alt="">-->
        <!--            <span>中</span>-->
        <!--          </li>-->
        <!--          <li @click="fileDir(2)" :class="[{'active': item === 2}]">-->
        <!--            <span>中</span>-->
        <!--            <img src="../../assets/images/translation/switch.png" alt="">-->
        <!--            <span>日</span>-->
        <!--          </li>-->
        <!--          <li @click="fileDir(3)" :class="[{'active': item === 3}]">-->
        <!--            <span>日</span>-->
        <!--            <img src="../../assets/images/translation/switch.png" alt="">-->
        <!--            <span>中</span>-->
        <!--          </li>-->
        <!--        </ul>-->
        <table style="width: 100%" cellpadding="0" cellspacing="0">
          <tbody>
          <tr v-for="(item,index) in tableData" style="width: 100%">
            <td style="width: 30%">
              <i @click="del(item)" v-if="item.status == 3 || item.status == 4" style="font-size: 16px;cursor: pointer"
                 class="el-icon-close"></i>
              {{item.createTime}}
            </td>
            <td style="width: 100px">
              <el-tooltip st effect="light" :content="item.fileName" placement="top">
                <span
                  style="width: 100px;display: inline-block;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">{{item.fileName}}</span>
              </el-tooltip>
            </td>
            <td style="width: 15%">{{item.translateCount}}</td>
            <td v-if="item.status != 3 && item.status != 4" style="width: 17%">
              <el-progress class="el_progress" :stroke-width="12" :percentage="item.progress"></el-progress>
            </td>
            <td v-if="item.status == 3" style="width: 17%">
              <img src="../../assets/images/translation/do.png" alt="">
            </td>
            <td v-if="item.status == 4" style="width: 17%">
              <img style="width: 16px;height: 16px" src="../../assets/images/translation/err.png" alt="">
            </td>
            <td v-if="item.status != 3 && item.status != 4" style="width: 20%;color: #2E54D9;cursor: not-allowed">翻译中
            </td>
            <td @click="" v-if="item.status == 3" @click="loadFile(item.taskCode,item.taskName,item)"
                style="width: 20%;color: #2E54D9;cursor: pointer">
              下载
            </td>
            <td @click="" v-if="item.status == 4" @click="againFile(item.taskCode,item.taskName)"
                style="width: 20%;color: #2E54D9;cursor: pointer">
              重新翻译
            </td>
          </tr>
          </tbody>
        </table>
        <el-pagination
          class="pagination"
          small
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="current"
          :page-size="7"
          layout="total, prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <div v-else-if="fileTran == 2" class="main-box">
      <div class="status-box">
        <!-- <span @click="serviceNameType == 2 ? toApplyEvent() :''" :class="{active:serviceNameType == 2}">{{serviceNameType === 0 ? $t(`personalCenter.server.try`):serviceNameType === 1 ? $t(`personalCenter.server.apply`):serviceNameType === 2 ? $t(`personalCenter.server.toApplyAbbr`) : ''}}</span> -->
      </div>
      <div :class="['main_box_left',{'focusActived':focusActived}]">
        <el-tabs style="margin-top: 5px;margin-left: 8px" v-model="languageDir2" @tab-click="handleClick">
          <el-tab-pane label="中英翻译" name="zh-cn_en-us"></el-tab-pane>
          <el-tab-pane label="中日翻译" name="zh-cn_ja-jp"></el-tab-pane>
        </el-tabs>
        <div class="uploads">
          <img src="../../assets/images/translation/upload.png" alt="">
          <div class="item1">支持格式为pdf文件,上限2w字</div>
          <div class="item2">仅翻译OA正文部分</div>
          <div @click="uploadFile2" class="item3">上传文件</div>
        </div>

        <el-upload
          class="upload-demo"
          action=""
          ref="upload2"
          :show-file-list="false"
          :on-change="uploadChangeFns2"
          :auto-upload="false">
        </el-upload>
      </div>
      <!--OA翻译历史列表-->
      <div class="main_box_rights">
        <table style="width: 100%" cellpadding="0" cellspacing="0">
          <tbody>
          <tr v-for="(item,index) in tableData" style="width: 100%">
            <td style="width: 30%">
              <i @click="del(item)" v-if="item.status == 3 || item.status == 4" style="font-size: 16px;cursor: pointer"
                 class="el-icon-close"></i>
              {{item.createTime}}
            </td>
            <td style="width: 100px">
              <el-tooltip st effect="light" :content="item.fileName" placement="top">
                <span
                  style="width: 100px;display: inline-block;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">{{item.fileName}}</span>
              </el-tooltip>
            </td>
            <td style="width: 15%">{{item.translateCount}}</td>
            <td v-if="item.status != 3 && item.status != 4" style="width: 17%">
              <el-progress class="el_progress" :stroke-width="12" :percentage="item.progress"></el-progress>
            </td>
            <td v-if="item.status == 3" style="width: 17%">
              <img src="../../assets/images/translation/do.png" alt="">
            </td>
            <td v-if="item.status == 4" style="width: 17%">
              <img style="width: 16px;height: 16px" src="../../assets/images/translation/err.png" alt="">
            </td>
            <td v-if="item.status != 3 && item.status != 4" style="width: 20%;color: #2E54D9;cursor: not-allowed">翻译中
            </td>
            <td @click="" v-if="item.status == 3" @click="loadFile(item.taskCode,item.taskName,item)"
                style="width: 20%;color: #2E54D9;cursor: pointer">
              下载
            </td>
            <td @click="" v-if="item.status == 4" @click="againFile(item.taskCode,item.taskName)"
                style="width: 20%;color: #2E54D9;cursor: pointer">
              重新翻译
            </td>
          </tr>
          </tbody>
        </table>
        <el-pagination
          class="pagination"
          small
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="current"
          :page-size="7"
          layout="total, prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
    </div>

<!--    <img @click="activeicon" class="activeicon" src="../../assets/images/translation/activeicon.png" alt="">-->

    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="login_dialog"
               :before-close="closeEvent">
      <login @loginDialog="loginDialog" :dialog-close="dialogClose" @dialogCloseBool="dialogCloseBool"></login>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisibles" class="long-dialog"
               @close="close">
      <div class="long-inner type2">
        <div class="choose-type">
          <p class="choose-tips">{{$t(`patentTranslation.choose_tips`)}}</p>
          <el-radio-group v-model="downType">
            <el-radio  :label="index" v-for="(item, index) in downTypeList" :disabled="!item.isCan" :key="index">
              {{ item.text }}</el-radio>
          </el-radio-group>
          <p class="btn-container">
            <el-button type="primary" class="el-blue" @click="confirmDownType">
              {{$t(`patentTranslation.confirmDownType`)}}
            </el-button>
          </p>
        </div>
      </div>
    </el-dialog>

<!--        <el-dialog :show-close="false" class="activeVisible" width="44%" title="" :visible.sync="activeVisible">-->
<!--          <img style="width: 100%;height: 100%" src="../../assets/images/translation/active.png" alt="">-->
<!--        </el-dialog>-->
  </div>
</template>

<script>
  import login from "@/components/common/login"
  import {mapState, mapMutations, mapGetters} from 'vuex'

  export default {
    name: "translation",
    metaInfo: {
      title: '专利翻译', // set a title
      meta: [{                 // set meta
        name: 'keyWords',
        content: '蓝灯鱼翻译,专利翻译,机器翻译,在线翻译,英语翻译,日语翻译,翻译api,pct翻译,神经网络翻译'
      }, {                 // set meta
        name: 'description',
        content: '基于1.2亿全球专利数据库，提取5000万+对译数据，通过神经网络机器学习算法，训练出适用于专利文件的AI翻译引擎，提高了翻译精准度和翻译效率，同时结果句式更加符合该行业特点。目前支持：中英翻译、英中翻译、中日翻译、日中翻译'
      }],
    },
    data() {
      return {
        //活动详情页
        activeVisible: true,
        //选择当前页
        current: 1,
        //总数
        total: 0,
        activeName: 'first',
        tableData: [],
        //是否文件翻译
        fileDirFlag: true,
        //定时器
        timer: null,
        //获取当前翻译中的数量
        count: 0,
        //当前文件翻译中的数量
        count1: 0,
        //当前OA翻译中的数量
        count2: 0,
        //选择语言方向
        languageDir: 'zh-cn_en-us',
        languageDir2: 'zh-cn_en-us',
        translateList: [
          {
            date: '2020-01-12',
            time: '16:02:35',
            file: '蓝灯鱼翻译文件.pdf',
            num: 2000,
            status: 0,
            progress: '20%'
          },
          {
            date: '2020-01-12',
            time: '16:02:35',
            file: '蓝灯鱼翻译文件.pdf',
            num: 2000,
            status: 0,
            progress: '20%'
          },
          {
            date: '2020-01-12',
            time: '16:02:35',
            file: '蓝灯鱼翻译文件.pdf',
            num: 2000,
            status: 0,
            progress: '20%'
          }
        ],
        //文件翻译方向选择
        item: '',
        //文件翻译
        fileTran: 0,
        //是否是中英文
        isCN: true,
        //翻译ID
        tranId: '',
        dialogVisibles: false,
        downTypeList: [
          {text: "txt", value: "txt", isCan: true},
          {text: "excel", value: "xlsx", isCan: true},
          {text: "word", value: "docx", isCan: true}
          // {text:"tmx",value:"tmx",isCan:false,},
        ],
        downType: 2,
        leftChoosed: 0,
        leftText: "",
        rightChoosed: 0,
        rightText: "",
        docName: "",
        //   文档/文字
        transType: 1,
        docText: "",
        dialogVisible: false,
        dialogClose: false,
        // 上传文档
        docProgress: 0,
        orderData: {},
        docTimer: '',
        docTanslateBool: false,
        translateSource: "",
        translateTarget: "",
        serviceNameType: '',
        unLoginBool: false,  //判断是否是登录状态,
        loading: 0,
        //   是否选择翻译语种
        languageDirectionBool: false,
        //   是否点击翻译语种
        clickLanguageBool: false,
        focusActived: false,
        mergeLanguageDirec: 'zh-cn_en-us',
        //   源语言方向
        sourceLanguageDirec: "zh-cn",
        //   源语言与识别语言是否一致
        languageIdenticalBool: false,
        //   进度条是否显示
        translatingingBool: false,
        //右侧结果框的进度条
        progressResult: 0,
        redisKey: '',
        translateTimer: '',
        translateType: '文本'
      };
    },
    components: {
      login,
    },
    created() {

    },
    mounted() {
      this.changeHeightNew(document.getElementById("symptomTxt"));
      if (!this.tool.getCookie("auth")) {
        this.unLoginBool = false;
      } else {
        this.unLoginBool = true;
      }
      if (this.$i18n.locale === 'cn') {
        this.isCN = true
      } else {
        this.isCN = false
      }
      if (this.$route.query.languageDirection) {
        var list = JSON.parse(decodeURIComponent(this.$route.query.languageDirection));
        this.leftChange(list, list.id - 1)
        this.leftText = localStorage.getItem('transValue')
        this.translateEvent()
      }
      if (!this.tool.getCookie("auth")) {
        return;
      }
      // //查看文档翻译中进度
      // this.getProgress()
      // //查看文档翻译中的量
      this.counts()
      // //获取用户翻译任务列表接口
      this.tranlatePages()
      // //下载翻译文档接口
      // this.loadFile()


    },
    beforeDestroy() {
      this.loading = false;
      clearInterval(this.docTimer);
      clearTimeout(this.translateTimer);
      clearInterval(this.timer)
    },
    watch: {
      count(newValue, oldValue) {
        if (newValue > 0 && !this.timer) {
          this.timer = setInterval(() => {
            //查看文档翻译中进度
            // this.getProgress()
            //查看文档翻译中的量
            this.counts()
            //获取用户翻译任务列表接口
            this.tranlatePages()
            //下载翻译文档接口
            // this.loadFile()
          }, 2000)
        }

        if (newValue == 0 && this.timer) {
          clearInterval(this.timer)
        }
      },
      fileTran() {
        if (this.tableData.length > 0) {
          this.fileDirFlag = false
        } else {
          this.fileDirFlag = true
        }
      },
      leftText(val) {
        if (val) {
          this.leftText = this.leftText.replace(/\t/g, " ")
        }
        let encn = this.enCnLength(this.leftText);
        if (encn == 'cn') {
          this.leftChange({
            "text": "中文",
            "languageDirection": "zh-cn",
            "isActive": false
          }, 1)
        } else if (encn == 'en') {
          this.leftChange({
            "text": "英文",
            "languageDirection": "en-us",
            "isActive": false
          }, 2)
        } else if (encn == 'ja') {
          this.leftChange({
            "text": "日文",
            "languageDirection": "ja-jp",
            "isActive": false
          }, 3)
        } else {
          this.leftChange({
            "text": "自动检测",
            "languageDirection": "",
            "isActive": false
          }, 0)
        }
        return;
      },
      countC(val) {
        if (val == 0) {
          this.languageDirectionBool = false;
        }
      }
    },
    methods: {
      ...mapMutations(['setMe', 'setNoticeList']),
      //426活动
      activeicon() {
        this.activeVisible = !this.activeVisible
      },
      copy(text) {
        if (text) {
          // //获取要赋值的input的元素
          // var inputElement = document.getElementById("copy_content");
          // //给input框赋值
          // inputElement.value = text;
          // //选中input框的内容
          // inputElement.select();
          // // 执行浏览器复制命令
          // document.execCommand("Copy");

          var textarea = document.createElement("textarea");
          textarea.value = text;
          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand("copy");
          document.body.removeChild(textarea);
          //提示已复制
          this.$message('已复制');
        } else {
          //提示已复制
          this.$message('暂无数据');
        }
      },
      //重新翻译
      async againFile(taskCode) {
        let data = await this.$axios.get('/lantern/document/tranlate/retry/' + taskCode)
        if (data.data.code == 0 && data.data.data) {
          this.$message({
            message: '重新翻译成功',
            type: 'success'
          });
          this.tranlatePages()
          this.counts()
        } else {
          this.$message.error('重新翻译失败');
        }
      },
      handleSizeChange(val) {
      },
      handleCurrentChange(val) {
        this.current = val
        //获取用户翻译任务列表接口
        this.tranlatePages()
      },
      handleClick(tab, event) {
        console.log(tab, event);
      },
      //删除
      async del(item) {
        let data = await this.$axios.delete('/lantern/document/tranlate/' + item.id)
        if (data.data.code == 0 && data.data.data) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.tranlatePages()
        } else {
          this.$message.error('删除失败');
        }

      },
      //上传的钩子
      uploadFile() {
        this.fileDirFlag = true
        if (!this.languageDir) {
          this.$message('请选择翻译方向');
          return;
        }
        this.$refs['upload'].$refs['upload-inner'].handleClick()
      },
      //OA翻译上传的钩子
      uploadFile2() {
        this.fileDirFlag = true
        if (!this.languageDir2) {
          this.$message('请选择翻译方向')
          return
        }
        this.$refs['upload2'].$refs['upload-inner'].handleClick()
      },
      //下载翻译文档接口
      loadFile(item, name, items) {
        this.$axios.get('/lantern/document/tranlate/download/' + item, {
          responseType: "arraybuffer"
        }).then((res) => {
          if (res.status == 200) {
            const content = res.data;
            const blob = new Blob([content]);
            let fileName = ''
            if (items.translateType == 3){
              fileName = name + ".pdf"
            }
            else {
              fileName = name + '.' + items.tarFileUrl.split('.').pop().toLowerCase();
            }
            if ('download' in document.createElement('a')) { // 非IE下载
              const elink = document.createElement('a');
              elink.download = fileName;
              elink.style.display = 'none';
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink)
            } else { // IE10+下载
              navigator.msSaveBlob(blob, fileName)
            }
          }
          if (res.status == 403) {
            this.$message.error(res.data.msg);
          }

        }).catch(err => {
          if (err.status == 403) {
            this.$message.error('您目前没有可用的翻译服务，请购买后再下载！');
          }
        })

      },
      //获取用户翻译任务列表接口
      async tranlatePages() {
        let {data} = await this.$axios.get('/lantern/document/tranlate/page?current=' + this.current + '&size=7')
        this.tableData = data.data.records
        this.total = data.data.total
      },
      //查看文档翻译进度接口
      async getProgress() {
        let data = await this.$axios.get('/lantern/document/tranlate/progess?taskCode=' + '0d6589dd4f944c9483933bc9816d08ae')
        if (data.data.code == 0) {
          this.$message({
            showClose: true,
            message: '文件上传成功',
            type: 'success'
          })
        }
        else if (data.data.code == 1) {
          this.$message({
            showClose: true,
            message: '文件上传失败',
            type: 'success'
          })
        }
      },
      //查看文档翻译中的量接口
      async counts() {
        let data = await this.$axios.get('/lantern/document/tranlate/countUnFinishedFileNumber')
        if(data.data.data == []){
          this.count1 = 0
          this.count2 = 0
        }
        else {
          let OCRCount = 0
          let OACount = 0

          for (let i = 0; i < data.data.data.length; i++){
            if (data.data.data[i].translateType == 2){
              OCRCount = data.data.data[i].countNumber
            }
            else if (data.data.data[i].translateType == 3){
              OACount = data.data.data[i].countNumber
            }
          }

          this.count1 = OCRCount
          this.count2 = OACount
        }

        this.count = this.count1 + this.count2
      },
      progress() {
        return;
      },
      fileDir(item) {
        this.item = item
        switch (item) {
          case 0:
            this.languageDir = 'zh-cn_en-us';
            break;
          case 1:
            this.languageDir = 'en-us_zh-cn';
            break;
          case 2:
            this.languageDir = 'zh-cn_ja-jp';
            break;
          case 3:
            this.languageDir = 'ja-jp_zh-cn';
            break;
        }
      },
      // 结果下载
      confirmDownType() {
        this.docProgressFn().begin();
        this.$axios.get(
          '/lantern/translateLan/downloadTranslateFileById/' + this.downTypeList[this.downType].text + '/' + this.tranId, {responseType: 'arraybuffer'}).then(res => {

          const content = res.data;
          const blob = new Blob([content]);
          const fileName = "翻译结果." + this.downTypeList[this.downType].value;
          if ("download" in document.createElement("a")) {
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            if ("msSaveOrOpenBlob" in navigator) {
              //为了兼容IE和edge浏览器的下载写的if  Google直接用else就可以
              elink.href = window.navigator.msSaveOrOpenBlob(
                blob,
                "翻译结果." + this.downTypeList[this.downType].value
              );
            } else {
              elink.href = URL.createObjectURL(blob);
            }
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          } else {
            navigator.msSaveBlob(blob, fileName);
          }
          this.dialogVisibles = false;
          this.downType = 2
          this.docProgressFn().end();
        })
          .catch(error => {
            this.$message.error('翻译结果下载失败');
          });
      },
      //关闭下载按钮
      close() {
        this.dialogVisibles = false;

      },
      //下载结果
      downloadFn() {
        // this.transData = "";
        // this.dialogType = 2;
        this.dialogVisibles = true;
        this.downType = 2
      },
      leftChange(item, index) {
        if (item.isActive) {
          return;
        }
        this.languageIdenticalBool = false;
        this.mergeLanguageDirec = "";
        this.$t(`patentTranslation.leftLanguage`).forEach((itm, idx) => {
          this.$set(this.$t(`patentTranslation.leftLanguage`)[idx], 'isActive', false)
        })
        this.$set(this.$t(`patentTranslation.leftLanguage`)[index], 'isActive', true)
        this.$t(`patentTranslation.rightLanguage`).forEach((itm, idx) => {
          this.$set(this.$t(`patentTranslation.rightLanguage`)[idx], 'isDisable', false)
          this.$set(this.$t(`patentTranslation.rightLanguage`)[idx], 'isActive', false)
        })
        if (item.text == "中文") {
          this.$t(`patentTranslation.rightLanguage`).forEach((itm, idx) => {
            if (item.text == itm.text) {
              this.$set(this.$t(`patentTranslation.rightLanguage`)[idx], 'isDisable', true)
            }
          })
          this.$t(`patentTranslation.rightLanguage`).forEach((itm, idx) => {
            this.$set(this.$t(`patentTranslation.rightLanguage`)[idx], 'isActive', false)
          })
        } else if (item.text != "中文") {
          this.$t(`patentTranslation.rightLanguage`).forEach((itm, idx) => {
            if (item.text == itm.text) {
              this.$t(`patentTranslation.rightLanguage`).forEach((itm, idx) => {
                this.$set(this.$t(`patentTranslation.rightLanguage`)[idx], 'isDisable', true)
              })
              this.$set(this.$t(`patentTranslation.rightLanguage`)[0], 'isDisable', false)
              this.$set(this.$t(`patentTranslation.rightLanguage`)[0], 'isActive', true)
            }
          })
        }

        this.$t(`patentTranslation.leftLanguage`).forEach((item, index) => {
          if (item.isActive) {
            this.sourceLanguageDirec = item.languageDirection;
          }
        })
        let languageDirection = "",
          languageDirectionText = '';
        if (this.leftText != '') {
          let encn = this.enCnLength(this.leftText);
          languageDirection = encn == "ja" ? "ja-jp" : encn == "cn" ? "zh-cn" : encn == 'en' ? "en-us" : "";
          // 识别的语言与源语言一致
          if (this.sourceLanguageDirec == languageDirection) {
            this.languageIdenticalBool = false;
            // this.translateAjax();
          } else {
            // 识别语言不一致添加提示
            this.languageIdenticalBool = true;
            languageDirectionText = encn == "ja" ? "日文" : encn == "cn" ? "中文" : encn == 'en' ? "英文" : "";
            this.$t(`patentTranslation.discernLan`).forEach((itm, idx) => {
              this.$set(this.$t(`patentTranslation.discernLan`)[idx], 'isHow', false)
              //   this.$set(this.$t(`patentTranslation.discernLan`)[idx], 'isActive', false)
              if (itm.text == languageDirectionText) {
                this.$set(this.$t(`patentTranslation.discernLan`)[idx], 'isHow', true)
                // this.$set(this.$t(`patentTranslation.discernLan`)[idx], 'isActive', true)
              }
            })
          }
        }

      },
      rightChange(item, index) {
        if (item.isActive) {
          return;
        }
        this.languageIdenticalBool = false;
        this.mergeLanguageDirec = "";
        this.$t(`patentTranslation.rightLanguage`).forEach((itm, idx) => {
          this.$set(this.$t(`patentTranslation.rightLanguage`)[idx], 'isActive', false)
        })
        this.$set(this.$t(`patentTranslation.rightLanguage`)[index], 'isActive', true)
        this.translateEvent()
      },
      // 识别语言切换
      discernEvent(item, index) {
        this.$t(`patentTranslation.leftLanguage`).forEach((itm, idx) => {
          if (item.languageDirection == itm.languageDirection) {
            this.$set(this.$t(`patentTranslation.leftLanguage`)[idx], 'isActive', false)
            this.leftChange(itm, idx);
          }
        })
        this.languageIdenticalBool = false;
        this.rightText = "";
      },
      deleteText() {
        this.leftText = '';
        this.rightText = '';
        this.docName = '';
        this.translateType = '文本';
        this.transType = 1;
        this.languageIdenticalBool = false;
        this.progressResult = 0;
        this.translatingingBool = false;
        clearTimeout(this.translateTimer);
        //   this.clearRequestFn();
        this.$nextTick(() => {
          this.$refs.textarea.focus();
        });
      },
      entering() {
        this.docName = '';
        this.translateType = '文本';
        if (!this.leftText) this.rightText = "";
        return;
        this.debounceLong(() => {
          this.translating();
        });
      },
      enCnLength(str) {
        str = str || "";
        let cnArr = str.match(/[\u4E00-\u9FA5\uF900-\uFA2D]/g) || [];
        let enArr = str.match(/\w+/g) || [];
        let jaArr = str.match(/[\u3040-\u309F\u30A0-\u30FF]/g) || [];
        let max = Math.max(cnArr.length,enArr.length,jaArr.length);
        if (!cnArr && !enArr && !jaArr) return null;
        if(max == cnArr.length ){
          if(jaArr.length / (cnArr.length + enArr.length + jaArr.length) >= 0.20){
            return "ja";
          }else {
            return "cn";
          }
        }else if(max == enArr.length ){
          return "en";
        }else if(max == jaArr.length ){
          return "ja";
        }
      },
      jaLength(str) {
        str = str || '';
        let jaArr = str.match(/[\u3040-\u309F\u30A0-\u30FF]/g);
        if (jaArr) return "ja";
      },
      htmlEscape(text) {
        return text.replace(/[<>"&]/g, function (match, pos, originalText) {
          switch (match) {
            case "<":
              return "&lt;";
            case ">":
              return "&gt;";
            case "&":
              return "&amp;";
            case "\"":
              return "&quot;";
          }
        });
      },
      pasteFn() {
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: "instant"
          });
        }, 311)
      },
      // 登录弹层隐藏
      loginDialog(data) {
        this.dialogVisible = data;
      },
      dialogCloseBool(data) {
        this.dialogClose = data;
      },
      closeEvent(done) {
        this.dialogClose = true;
        done();
      },
      // 翻译
      translateEvent() {
        if (this.tool.getCookie("auth")) {
          if (this.countC > 3000) {
            return;
          }
          this.redisKey = this.randomString(5);
          this.verificationTranslateAjax();
        } else {
          this.dialogVisible = true
        }

      },
      // 蓝灯鱼专利翻译文本内容验证权限
      async verificationTranslateAjax() {
        this.$t(`patentTranslation.leftLanguage`).forEach((item, index) => {
          if (item.isActive) {
            this.sourceLanguageDirec = item.languageDirection;
            this.$t(`patentTranslation.rightLanguage`).forEach((itm, idx) => {
              if (!itm.isDisable && itm.isActive) {
                this.mergeLanguageDirec = item.languageDirection + "_" + itm.languageDirection
              }
            })
          }
        })
        if (this.mergeLanguageDirec != '') {
          if (this.docName == "") {// 机器翻译
            if (!this.leftText) {
              return;
            }
            let data = await this.$axios.post(
              '/lantern/translateLan/verificationTranslate', {
                // translateSource: this.htmlEscape(this.leftText),
                translateSource: this.leftText,
                languageDirection: this.mergeLanguageDirec,
              }
            )
            if (data.data.code == 0) {
              this.txtTanslate();
            } else if (data.data.code == 1) {
              this.errorCommon(data);
            }
            // 人工翻译
          } else {
            let obj = {
              file: this.file.raw,
              languageDirection: this.mergeLanguageDirec
            }
            this.verificationDocAjax(obj);
          }
        } else {
          this.languageIdenticalBool = false;
        }
      },
      // 蓝灯鱼专利翻译word文档是否有权限
      async verificationDocAjax(params) {
        const formData = new FormData();
        for (let i in params) {
          formData.append(i, params[i]);
        }
        let data = await this.$axios.post(
          '/lantern/translateLan/verificationDoc', formData)
        if (data.data.code == 0) {
          this.txtTanslate();
        } else if (data.data.code == 1) {
          this.errorCommon(data);
        }
      },
      //生成随机字符串
      randomString(len) {
        len = len || 32;
        /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
        var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
        var maxLen = chars.length;
        var str = '';
        for (var i = 0; i < len; i++) {
          str += chars.charAt(Math.floor(Math.random() * maxLen));
        }
        return str;
      },
      // 进度条
      getTranslateTargetFn() {
        var _this = this;
        this.$axios.post(
          'lantern/translateLan/getTranslateTarget', {
            redisKey: this.redisKey
          }).then((data) => {
          if (data.data.code == 0) {
            this.progressResult = parseInt(data.data.data.Percentage);
            if (parseInt(data.data.data.Percentage) >= 100) {
              this.translatingingBool = false;
              clearTimeout(this.translateTimer)
            } else {
              clearTimeout(this.translateTimer)
              this.translateTimer = setTimeout(() => {
                _this.getTranslateTargetFn();
              }, 500);
            }
          }
        })
      },
      async txtTanslate() {
        let languageDirection = "",
          languageDirectionText = '';
        if (this.mergeLanguageDirec != '') {
          if (this.docName == "") {// 机器翻译
            this.rightText = "";
            this.progressResult = 0;
            this.translatingingBool = true;
            this.getTranslateTargetFn();
            let encn = this.enCnLength(this.leftText);
            let ja = this.jaLength(this.leftText);
            languageDirection = ja == "ja" ? "ja-jp" : encn == "cn" ? "zh-cn" : encn == 'en' ? "en-us" : "";
            // 识别的语言与源语言一致
            if (this.sourceLanguageDirec == languageDirection) {
              this.languageIdenticalBool = false;
              // this.translateAjax();
            } else {
              // 识别语言不一致添加提示
              this.languageIdenticalBool = true;
              languageDirectionText = ja == "ja" ? "日文" : encn == "cn" ? "中文" : encn == 'en' ? "英文" : "";
              this.$t(`patentTranslation.discernLan`).forEach((itm, idx) => {
                this.$set(this.$t(`patentTranslation.discernLan`)[idx], 'isHow', false)
                //   this.$set(this.$t(`patentTranslation.discernLan`)[idx], 'isActive', false)
                if (itm.text == languageDirectionText) {
                  this.$set(this.$t(`patentTranslation.discernLan`)[idx], 'isHow', true)
                  // this.$set(this.$t(`patentTranslation.discernLan`)[idx], 'isActive', true)
                }
              })
            }
            let data = await this.$axios.post(
              '/lantern/translateLan/txtTanslate', {
                languageDirection: this.mergeLanguageDirec,
                // translateSource: this.htmlEscape(this.leftText),
                translateSource: this.leftText,
                redisKey: this.redisKey,
                translateType: this.translateType,
              }, {timeout: 125 * 1000}
            )
            if (data.data.code == 0) {
              if (!this.leftText) return;
              this.translatingingBool = false;
              clearTimeout(this.translateTimer)
              this.rightText = data.data.data.translateTarget;
              this.tranId = data.data.data.id
              this.updateCountAjax(data.data.data.id);
            } else if (data.data.code == 1) {
              this.translatingingBool = false;
              clearTimeout(this.translateTimer);
              this.$message({
                message: data.data.msg
              });
            }

          } else {
            let obj = {
              translateVersion: "zh-cn",
              languageDirection: this.mergeLanguageDirec,
              file: this.file.raw
            }
            this.docTanslateAjax(obj);
          }
        } else {
          this.languageIdenticalBool = false;
        }
      },
      errorCommon(data) {
        if (data.data.data != null) {
          if (data.data.data.isLogin == 'false') {
            if (data.data.data.type == "10") {
              this.$confirm(this.$t('patentTranslation.confirmTitle2'), this.$t('patentTranslation.tips'), {
                confirmButtonText: this.$t('patentTranslation.goLogin'),
                cancelButtonText: this.$t('patentTranslation.cancelBtn'),
                type: 'warning'
              }).then(() => {
                this.dialogVisible = true;
              }).catch(() => {
                if (this.docName != "") {
                  this.file = "";
                  this.docName = "";
                  this.translateType = '文本';
                  clearInterval(this.docTimer);
                }
              })
            } else if (data.data.data.type == "3000") {
              this.$message({
                message: data.data.msg
              });
            } else {
              this.setMe('');
              this.setNoticeList('');
              this.$message({
                message: data.data.msg
              });
            }
          } else if (data.data.data.isLogin == 'true') {
            if (data.data.data.isFirstLogin == 'true') {
              this.$confirm(this.$t('patentTranslation.confirmTitle3'), this.$t('patentTranslation.tips'), {
                confirmButtonText: this.$t('patentTranslation.apply'),
                cancelButtonText: this.$t('patentTranslation.cancelBtn'),
                type: 'warning'
              }).then(() => {
                this.updateIsFirstClickAjax();
                if (data.data.data.translateType == '1') {
                  this.$message({
                    message: "您已经提交申请，审核通过后，会给您发送通知"
                  });
                } else {
                  this.$router.push({
                    path: "/account/trialInfo",
                    query: {
                      serverType: 2
                    }
                  })
                }
              }).catch(() => {
                this.updateIsFirstClickAjax();
              });
            } else if (data.data.data.isFirstLogin == 'false') {
              if (data.data.data.type == "10") {
                if (data.data.data.translateType == '1' || data.data.data.translateType == '2') {
                  this.$confirm(this.$t('patentTranslation.confirmTitle1'), this.$t('patentTranslation.tips'), {
                    confirmButtonText: this.$t('patentTranslation.apply'),
                    cancelButtonText: this.$t('patentTranslation.cancelBtn'),
                    type: 'warning'
                  }).then(() => {
                    if (data.data.data.translateType == '1') {
                      this.$message({
                        message: "您已经提交申请，审核通过后，会给您发送通知"
                      });
                    } else {
                      this.$router.push({
                        path: "/account/trialInfo",
                        query: {
                          serverType: 2
                        }
                      })
                    }
                  }).catch(() => {
                    if (this.docName != "") {
                      this.file = "";
                      this.docName = "";
                      this.translateType = '文本';
                      clearInterval(this.docTimer);
                    }
                  })
                }
              } else if (data.data.data.type == "3000") {
                this.$message({
                  message: data.data.msg
                });
              } else {
                this.$message({
                  message: data.data.msg
                });
              }
            }
          }
        } else {
          this.$confirm(this.$t(`patentTranslation.tipsText`), this.$t(`patentTranslation.tips`), {
            confirmButtonText: this.$t(`patentTranslation.goBuy`),
            cancelButtonText: this.$t(`patentTranslation.cancelBtn`),
            center: true,
            customClass: "time_confirm",
            closeOnClickModal: false
          }).then(() => {
            this.$router.push({
              path: "/personalCenter/productService",
              query: {
                serverId: 26,
                typeId: 28
              }
            })
          }).catch(() => {
          })
        }
      },
      // 上传文档
      async uploadChangeFn(file) {
        this.translateType = '文档';
        this.languageIdenticalBool = false;
        let suffixArr = ["doc", "docx", "txt", "pdf"];
        let suffix = file.name.split('.').pop().toLowerCase();
        if (!suffixArr.includes(suffix)) {
          this.$message(this.$t('patentTranslation.errMessage'));
          return;
        }
        if (this.docTanslateBool) {
          this.docTanslateBool = !this.docTanslateBool;
        }
        this.file = file;
        this.docName = file.name;
        this.docText = file.name;
        this.transType = 2;
        this.languageDirectionBool = false;
        this.clickLanguageBool = false;
        this.languageIdenticalBool = false;
      },
      // 生成文档翻译任务接口
      async uploadChangeFns(file) {
        let suffixArr = ["doc", "docx", "txt", "pdf"]
        let suffix = file.name.split('.').pop().toLowerCase()
        if (!suffixArr.includes(suffix)) {
          this.$message(this.$t('patentTranslation.errMessage'))
          return
        }

        let params = {
          file: file.raw,
          languageDirection: this.languageDir
        }
        const formData = new FormData();
        for (let i in params) {
          formData.append(i, params[i]);
        }
        let data = await this.$axios.post(
          '/lantern/document/tranlate/gentask', formData)
        if (data.data.code == 0) {
          this.fileDirFlag = false
          this.item = ''
          this.$message({
            showClose: true,
            message: '文件上传成功',
            type: 'success'
          });
          this.counts()
          //获取用户翻译任务列表接口
          this.tranlatePages()
        }
        else if (data.data.code == 1) {
          this.$message({
            showClose: true,
            message: data.data.msg,
            type: 'success'
          });
        }
      },

      async uploadChangeFns2(file){
                let suffix = file.name.split('.').pop().toLowerCase()
        if (suffix != 'pdf') { return }
        let params = {
          file: file.raw,
          languageDirection: this.languageDir2
        }
        const formData = new FormData();
        for (let i in params) {
          formData.append(i, params[i]);
        }
        let data = await this.$axios.post(
          '/lantern/document/tranlate/translateExaminationOpinionNoticePdf', formData)
        if (data.data.code == 0) {
          this.fileDirFlag = false
          this.item = ''
          this.$message({
            showClose: true,
            message: '文件上传成功',
            type: 'success'
          });
          this.counts()
          //获取用户翻译任务列表接口
          this.tranlatePages()
        }
        else if (data.data.code == 1) {
          this.$message({
            showClose: true,
            message: data.data.msg,
            type: 'success'
          });
        }
      },
      // 取消翻译  旧版功能
      calcelTranslate() {
        if (this.docTanslateBool) {
          this.docTanslateBool = !this.docTanslateBool;
          this.docProgressFn().end();
          clearInterval(this.docTimer);
        }
        this.file = "";
        this.docName = "";
      },
      // 获取翻译结果
      async docTanslateAjax(params) {
        this.leftText = '';
        this.rightText = '';
        const formData = new FormData();
        for (let i in params) {
          formData.append(i, params[i]);
        }
        let data = await this.$axios.post(
          '/lantern/translateLan/docTanslate', formData)
        if (data.data.code == 0) {
          this.leftText = data.data.data.translateSource;
          // this.rightText = data.data.data.translateTarget.replace(/\n/g, "<br>");
          // this.updateCountAjax(data.data.data.id);
          this.docName = "";
          this.txtTanslate();
          this.$nextTick(() => {
            clearInterval(this.docTimer);
          });
        } else if (data.data.code == 1) {
          this.docTanslateBool = false;
          clearInterval(this.docTimer);
          this.$message({
            message: data.data.msg
          });
        }
      },
      // 更新是否是第一次使用服务的状态
      async updateIsFirstClickAjax() {
        let data = await this.$axios.put("/lantern/serviceLan/updateIsFirstClick", {
          params: {
            serviceName: 2
          }
        })
      },
      // 获取服务是否是试用结束后第一次登陆   ( 暂时未用到 )
      async getIsFirstClickAjax() {
        let data = await this.$axios.get("/lantern/serviceLan/getIsFirstClick/2")
        if (data.data.code == 1) {
          if (data.data.data.isFirstClick == 'true') {
            this.$confirm(this.$t('patentTranslation.confirmTitle3'), this.$t('patentTranslation.tips'), {
              confirmButtonText: this.$t('patentTranslation.apply'),
              cancelButtonText: this.$t('patentTranslation.cancelBtn'),
              type: 'warning'
            }).then(() => {
              this.updateIsFirstClickAjax();
              if (this.serviceNameType == 1) {
                this.$message({
                  message: "您已经提交申请，审核通过后，会给您发送通知"
                });
              } else {
                this.$router.push({
                  path: "/account/trialInfo",
                  query: {
                    serverType: 2
                  }
                })
              }
            }).catch(() => {
              this.updateIsFirstClickAjax();
            });
          } else if (data.data.data.isFirstClick == 'false') {
            this.verificationTranslateAjax();
          }
        }
      },
      // 记录翻译次数
      async updateCountAjax(paramsCount) {
        let data = await this.$axios.put('/lantern/translateLan/updateCount', {
          id: paramsCount
        });
      },
      docProgressFn() {
        return {
          begin: () => {
            this.orderData = {};
            this.docProgress = 0;
            clearInterval(this.docTimer);
            this.docTimer = setInterval(() => {
              this.docProgress += 0.2;
              if (this.docProgress > 99) {
                clearInterval(this.docTimer);
              }
            }, 200);
          },
          end: () => {
            this.dialogVisible = false;
            clearInterval(this.docTimer);
          }
        }
      },
      changeHeightNew(elem, extra, maxHeight) {
        extra = extra || 0;
        var isFirefox = !!document.getBoxObjectFor || 'mozInnerScreenX' in window,
          isOpera = !!window.opera && !!window.opera.toString().indexOf('Opera'),
          addEvent = function (type, callback) {
            elem.addEventListener ?
              elem.addEventListener(type, callback, false) :
              elem.attachEvent('on' + type, callback);
          },
          getStyle = elem.currentStyle ? function (name) {
            var val = elem.currentStyle[name];
            if (name === 'height' && val.search(/px/i) !== 1) {
              var rect = elem.getBoundingClientRect();
              return rect.bottom - rect.top -
                parseFloat(getStyle('paddingTop')) -
                parseFloat(getStyle('paddingBottom')) + 'px';
            }
            return val;
          } : function (name) {
            return getComputedStyle(elem, null)[name];
          },
          minHeight = parseFloat(getStyle('height'));
        elem.style.resize = 'none';
        var change = function () {
          var scrollTop, height,
            padding = 0,
            style = elem.style;
          if (elem._length === elem.value.length) return;
          elem._length = elem.value.length;
          if (!isFirefox && !isOpera) {
            padding = parseInt(getStyle('paddingTop')) + parseInt(getStyle('paddingBottom'));
          }
          scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
          elem.style.height = minHeight + 'px';
          if (elem.scrollHeight > minHeight) {
            if (maxHeight && elem.scrollHeight > maxHeight) {
              height = maxHeight - padding;
              style.overflowY = 'auto';
            } else {
              height = elem.scrollHeight - padding;
              style.overflowY = 'hidden';
            }
            style.height = height + extra + 'px';
            scrollTop += parseInt(style.height) - elem.currHeight;
            document.body.scrollTop = scrollTop;
            document.documentElement.scrollTop = scrollTop;
            elem.currHeight = parseInt(style.height);
          }
        };
        addEvent('propertychange', change);
        addEvent('input', change);
        addEvent('focus', change);
        change();
      },
      // 全角转半角
      toCDB(str) {
        var tmp = "";
        for (var i = 0; i < str.length; i++) {
          if (str.charCodeAt(i) == 12288) {
            tmp += String.fromCharCode(str.charCodeAt(i) - 12256);
            continue;
          }
          if (str.charCodeAt(i) > 65280 && str.charCodeAt(i) < 65375) {
            tmp += String.fromCharCode(str.charCodeAt(i) - 65248);
          } else {
            tmp += String.fromCharCode(str.charCodeAt(i));
          }
        }
        return tmp
      }
    },
    computed: {
      ...mapState(['me']),
      countC() {
        if (!this.leftText) return 0;
        let arr = this.toCDB(this.leftText).match(/([\u2E80-\u2FDF\u3040-\u318F\u31A0-\u31BF\u31F0-\u31FF\u3400-\u4DB5\u4E00-\u9FFF\uA960-\uA97F\uAC00-\uD7FF]{1})|([a-zA-Z0-9]+)/g);
        arr = arr || [];
        return arr.length;
      },
    }
  }
</script>
<style scoped>
  /*修改滚动条样式*/
  #symptomTxt::-webkit-scrollbar-track{
    background: rgb(239, 239, 239);
    border-radius:2px;
  }
  #symptomTxt::-webkit-scrollbar-thumb{
    background: #bfbfbf;
    border-radius:5px;
  }
  #symptomTxt::-webkit-scrollbar-thumb:hover{
    background: #333;
  }
  #symptomTxt::-webkit-scrollbar-corner{
    background: #179a16;
  }
  /*修改滚动条样式*/
  #symptomTxt::-webkit-scrollbar{
    width:5px;
    height:5px;
    /**/
  }



  .inner::-webkit-scrollbar-track{
    background: rgb(239, 239, 239);
    border-radius:2px;
  }
  .inner::-webkit-scrollbar-thumb{
    background: #bfbfbf;
    border-radius:5px;
  }
  .inner::-webkit-scrollbar-thumb:hover{
    background: #333;
  }
  .inner::-webkit-scrollbar-corner{
    background: #179a16;
  }
  /*修改滚动条样式*/
  .inner::-webkit-scrollbar{
    width:5px;
    height:5px;
    /**/
  }
</style>
<style lang='less'>
  .dib-vam-fz0 > * {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
  }

  .activeVisible {
    .el-dialog {
      background: transparent;
      -webkit-box-shadow: none;
    }
  }

  .translationIndex {
    .alert {
      position: absolute;
      top: 90px;
    }

    .activeicon {
      width: 136px;
      height: 119px;
      position: fixed;
      top: 360px;
      right: 135px;
      z-index: 9999;
      cursor: pointer;
    }

    @media screen and (max-width: 1750px) {
      .activeicon {
        right: 50px;
      }
    }

    @media screen and (max-width: 1560px) {
      .activeicon {
        right: 0px;
      }
    }

    .long-dialog.el-dialog__wrapper {
      text-align: center;

      .el-dialog {
        display: inline-block;
        width: auto;

        .el-dialog__header {
          padding: 0;
        }

        .el-dialog__body {
          padding: 0;
        }
      }

      &.bg-transparent {
        .el-dialog {
          background: transparent;
          box-shadow: none;

          .el-dialog__header {
            display: none;
          }
        }
      }
    }

    .long-inner {
      &.type0 {
      }
      &.type1 {
        background: transparent;
      }
      &.type2 {
        width: 560px;
        height: 340px;
        padding: 63px 30px 0;
        box-sizing: border-box;
        .choose-tips {
          text-align: center;
          font-size: 16px;
          letter-spacing: 1px;
          color: #333333;
          padding-bottom: 17px;
          border-bottom: 1px solid #dcdcdc;
          margin-bottom: 63px;
        }
        .el-radio-group {
          /*padding-left: 117px;*/
          margin-bottom: 69px;
        }
        .btn-container {
          text-align: center;
          .el-blue {
            width: 104px;

          }
        }
      }
      &.type3 {
        height: 356px;
        width: 660px;
        padding: 60px 70px 0;
      }

      .doc-loading {
        height: 60px;
        width: 447px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        .loading-outer {
          width: 100%;
          height: 8px;
          background-color: #dfdfdf;
          border-radius: 4px;
          .loading-inner {
            width: 140px;
            height: 8px;
            background-color: #3896cd;
            border-radius: 4px;
          }
        }
        .text {
          text-align: center;
          font-size: 14px;
          letter-spacing: 0px;
          color: #243c5e;
          margin-top: 38px;
        }
      }
      .deduction-notice {
        h2 {
          text-align: center;
          font-size: 24px;
          letter-spacing: 2px;
          color: #333333;
          margin-bottom: 24px;
        }
        .text {
          font-size: 18px;
          letter-spacing: 1px;
          color: #333333;
          line-height: 30px;
          margin-bottom: 10px;
          text-align: center;
        }
        .count {
          letter-spacing: 1px;
          font-size: 34px;
          color: #f54848;
          text-align: center;
          .unit {
            font-size: 20px;
            color: #333333;
          }
        }
        .pay-button-container {
          text-align: center;
          margin-top: 36px;
          .el-white2blue {
            width: 130px;
            height: 40px;
          }
          .el-white2blue + .el-white2blue {
            margin-left: 66px;
          }
        }

        .vipcount {
          font-size: 20px;
          color: #888888;
          text-align: center;
          margin-top: 17px;
        }
      }
    }

    min-height: calc(100vh - 264px);
    padding-top: 234px;
    padding-bottom: 30px;
    min-width: 1200px;
    background: #fff;

    .cp {
      cursor: pointer;
    }

    .trans_tab {
      max-width: 1200px;
      margin: 0 auto;

      .trans_tab_cen {
        width: 300px;
        background: url("../../assets/images/patent/tutorial_item1.png") 100% 100% no-repeat;
        background-size: 290px 40px;
        display: flex;

        div {
          cursor: pointer;
          width: 115px;
          height: 40px;
          display: flex;
          align-items: center;
          padding-left: 25px;
          box-sizing: border-box;
          font-size: 14px;
          font-weight: 400;
          color: #5F5F5F;

          &:nth-of-type(1) {
            font-size: 14px;
            font-weight: 500;
            color: #FFFFFF;
          }

          &:nth-of-type(2) {
            position: relative;

            .dot {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              width: 23px;
              height: 23px;
              border-radius: 50%;
              background: #fb5380;
              border: 1px solid #FFFFFF;
              font-size: 12px;
              font-weight: 500;
              color: #FFFFFF;
              top: -10px;
              right: 10px;
            }
          }

          &:nth-of-type(3) {
            position: relative;

            .dot {
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              width: 23px;
              height: 23px;
              border-radius: 50%;
              background: #fb5380;
              border: 1px solid #FFFFFF;
              font-size: 12px;
              font-weight: 500;
              color: #FFFFFF;
              top: -10px;
              right: 10px;
            }
          }

        }

        &.active1 {
          background: url("../../assets/images/patent/tutorial_item2.png") 100% 100% no-repeat;
          background-size: 290px 40px;

          div:nth-of-type(1) {
            font-weight: 400;
            color: #5F5F5F !important;
          }

          div:nth-of-type(2) {
            font-size: 14px;
            font-weight: 500;
            color: #FFFFFF;
          }

          div:nth-of-type(3) {
            font-weight: 400;
            color: #5F5F5F !important;
          }
        }

        &.active2 {
          background: url("../../assets/images/patent/tutorial_item3.png") 100% 100% no-repeat;
          background-size: 290px 40px;

          div:nth-of-type(1) {
            font-weight: 400;
            color: #5F5F5F !important;
          }

          div:nth-of-type(2) {
            font-weight: 400;
            color: #5F5F5F !important;
          }

          div:nth-of-type(3) {
            font-size: 14px;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
      }
    }

    .main-box {
      max-width: 1200px;
      margin: 0 auto;
      min-height: 336px;
      background-color: #ffffff;
      position: relative;
      display: flex;
      /*background: red;*/
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
      border-radius: 0px 8px 8px 8px !important;
      overflow: hidden;

      .status-box {
        width: 110px;
        height: 109px;
        position: absolute;
        right: 0;
        top: -170px;

        span {
          position: absolute;
          bottom: 25px;
          left: 12px;
          font-size: 14px;
          color: #6c94fa;

          &.active {
            cursor: pointer;
          }
        }
      }

      .box-top {
        height: 50px;
        margin-bottom: 15px;

        .language-item {
          & + .language-item {
            margin-left: 37px;
          }

          letter-spacing: 0px;
          color: #515a71;
          line-height: 50px;
          padding: 0 8px;
          position: relative;
          font-weight: 700;
          /*font-family: Arial;*/

          &:hover {
            color: @blue;
          }

          &.active {
            color: @blue;

            &:after {
              position: absolute;
              /*transition: all 0.3s linear;*/
              top: 47px;
              left: 50%;
              margin-left: -8px;
              content: "";
              width: 16px;
              height: 3px;
              background: @blue;
            }
          }

          &.disable {
            cursor: default;
            color: #c2c6d0;
          }
        }

        .el-white2blue {
          /*width: 98px;*/
          height: 30px;
          background-color: #ffffff;
          border-radius: 5px;
          border: solid 1px #c7c7c7;
          color: #969696;
          /*margin-right: 12px;*/
        }

        .box-item-left {
          .box_language {
            li {
              margin-right: 30px;
              cursor: pointer;
              position: relative;
              padding: 0 5px;
              font-weight: 700;
              color: #595a5e;

              &:hover {
                color: @blue;
              }

              &.active {
                color: @blue;

                &:after {
                  position: absolute;
                  /*transition: all 0.3s linear;*/
                  top: 46px;
                  left: 0;
                  content: "";
                  width: 100%;
                  height: 3px;
                  background: @blue;
                }
              }

              img {
                display: inline-block;
                width: 14px;
                vertical-align: 3px;
              }
            }
          }

          .uploadClass {
            font-size: 14px;
            letter-spacing: 0px;
            color: #243c5e;
            margin-left: 42px;
            vertical-align: -6px;

            .upload-box:focus {
              outline: none;
            }

            img {
              width: 26px;
              display: block;

              &.upload_hover {
                display: none;
              }
            }

            &:hover {
              .upload {
                display: none;
              }

              .upload_hover {
                display: inline-block;
              }

              .doc_format {
                display: block;
                position: absolute;
              }
            }

            .doc_format {
              display: none;
              color: @blue;
              position: absolute;
              top: 0;
              width: 300px;
              left: 38px;
            }
          }
        }
      }

      .overflow-tips {
        width: 100%;
        height: 38px;
        line-height: 38px;
        background-color: #e2eefc;
        padding-left: 38px;
        margin-left: -38px;
        padding-right: 37px;

        .el-icon-warning {
          font-size: 16px;
          color: #0479ff;
          margin-right: 12px;
          vertical-align: middle;
        }

        span {
          font-size: 14px;
          letter-spacing: 0px;
          color: #0479ff;
          vertical-align: middle;
        }
      }

      .box-body {
        min-height: 290px;
        /*background: orange;*/
        position: relative;
        padding-bottom: 60px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -o-box-sizing: border-box;
        box-sizing: border-box;
        .ai {
          width: 92px;
          height: auto;
          position: absolute;
          bottom: 10px;
          right: -10px;
        }
        &.overflow-style {
          /*padding-bottom: 56px;*/

          .box-item-left,
          .box-item-right {
            width: 100%;

            .source {
              margin-top: 45px;
            }
          }

        }

        font-size: 0;

        .box-item-left,
        .box-item-right {
          display: inline-block;
          width: 100%;
          font-size: 14px;
          vertical-align: top;
          /*background: yellow;*/

          .source {
            font-size: 14px;
            letter-spacing: 0px;
            color: #666;
            margin-top: 25px;
            font-weight: bold;
            position: relative;

            i.el-icon-close {
              position: absolute;
              right: 0;
              top: 0;
            }
          }

          .inner {
            &.shorter {
              min-height: 175px; //226-14-25-12px
            }

            /*background: blue;*/
            margin-top: 15px;
            width: 100%;
            max-height: 230px;
            overflow-x: hidden;
          }
        }

        .box-item-left {
          position: relative;
          /*border-right: 1px solid #c7c7c7;*/

          .el-textarea {
            &.shorter {
              .el-textarea__inner {
                height: 175px;
              }
            }

            .el-textarea__inner {
              /*height: 226px;*/
              border: 0;
              font-size: 15px;
              line-height: 20px;
              letter-spacing: 0px;
              color: #333;
              padding: 0px;
              overflow: hidden;
              font-family: Microsoft YaHei;
            }
          }

          .el-icon-close {
            font-size: 20px;
            color: #cdcfd3;
            font-weight: bold;
            position: absolute;
            right: -20px;
            top: 15px;
            cursor: pointer;

            &:hover {
              color: @blue;
            }
          }
        }

        .box-item-right {
          position: relative;


          .result {
            font-size: 15px;
            line-height: 20px;
            letter-spacing: 0px;
            /*background: #000;*/
            font-family: Microsoft YaHei;
          }

        }

      }

      .box-body-bottom {
        position: absolute;
        left: 0;
        bottom: 18px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        color: #515a71;
        font-size: 14px;
        padding: 0 38px;
        box-sizing: border-box;

        .source_language {
          span {
            font-size: 18px;
            color: @blue;
            cursor: pointer;
          }
        }

        .count {
          letter-spacing: 0px;
        }
      }

      .main_box_left,
      .main_box_right {
        display: inline-block;
        flex: 1;
        background: #fff;
        padding-left: 38px;
        padding-right: 38px;
        box-sizing: border-box;
        .progressResult {
          height: 30px;
          width: 250px;
          position: absolute;
          top: 196px;
          /* bottom: 0; */
          left: 600px;
          right: 0;
          margin: auto;

          .loading-outer {
            width: 100%;
            height: 5px;
            background-color: #dbdee5;
            border-radius: 3px;

            .loading-inner {
              width: 260px;
              height: 5px;
              background-color: @blue;
              border-radius: 3px;
            }
          }

          .text {
            text-align: center;
            font-size: 14px;
            letter-spacing: 0px;
            color: #515a71;
            margin-top: 16px;
          }
        }

        .trinList {
          margin-top: 56px;
          margin-left: 150px;

          li {
            padding: 0 48px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: space-between;
            width: 238px;
            height: 60px;
            border-radius: 8px;

            img {
              width: 34px;
              height: 24px;
            }

            span {
              width: 21px;
              height: 29px;
              font-size: 21px;
              font-weight: 500;
              color: #091567;
              line-height: 29px;
            }

            &.active {
              background: rgba(204, 216, 255, 0.52);
            }

            &:hover {
              background: rgba(204, 216, 255, 0.52);
            }
          }

        }
      }

      .main_box_rights {
        display: inline-block;
        flex: 1;
        background: #fff;
        box-sizing: border-box;
        background: #F2F6FF;

        .trinList {
          margin-top: 56px;
          margin-left: 150px;

          li {
            padding: 0 48px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: space-between;
            width: 238px;
            height: 60px;
            border-radius: 8px;

            img {
              width: 34px;
              height: 24px;
            }

            span {
              width: 21px;
              height: 29px;
              font-size: 21px;
              font-weight: 500;
              color: #091567;
              line-height: 29px;
            }

            &.active {
              background: rgba(204, 216, 255, 0.52);
            }

            &:hover {
              background: rgba(204, 216, 255, 0.52);
            }
          }
        }

        table {
          /*display: block;*/
          /*height: 355px;*/
          overflow-y: scroll;
          tr {
            width: 606px;
            height: 40px;
            td {
              text-align: center;
              font-size: 12px;
              font-weight: 400;
              color: #666666;
              border-bottom: 1px solid #DFE5FC;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              .el_progress {
                .el-progress__text {
                  font-size: 12px !important;
                  font-weight: 400 !important;
                  color: #9DA6C8 !important;
                }
              }
            }

            i {
              display: none;
            }

            &:hover {
              background: #ffffff;

              i {
                cursor: pointer;
                display: inline-block;
              }
            }
          }
        }

        .pagination {
          position: absolute;
          right: 10px;
          bottom: 10px;
        }
      }

      .main_box_left {
        /*border: 1px solid #c2c6d0;*/
        /*border-right: none;*/
        /*border-radius: 3px 0 0 3px;*/
        position: relative;

        .uploads {
          text-align: center;

          img {
            margin-top: 30px;
          }

          .item1 {
            margin-top: 25px;
          }

          .item2 {
            margin-top: 10px;
          }

          .item3 {
            margin-top: 26px;
            width: 128px;
            height: 34px;
            background: #2E54D9;
            border-radius: 12px;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 34px;
            display: inline-block;
          }
        }

        .el-upload-dragger {
          div {
            font-size: 14px;
            font-weight: 400;
            color: #A8AEC3;
            line-height: 20px;
          }

          .upload {
            width: 600px;
            height: 336px;
            border: none;

            img {
              margin-top: 87px;
            }

            .item1 {
              margin-top: 25px;
            }

            .item2 {
              margin-top: 10px;
            }

            .item3 {
              margin-top: 26px;
              width: 128px;
              height: 34px;
              background: #2E54D9;
              border-radius: 12px;
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 34px;
              display: inline-block;
            }
          }

        }

        &::after {
          content: "";
          height: 100%;
          width: 1px;
          background: #C7CFEB;
          position: absolute;
          right: 1px;
          top: 0;
        }

        &.focusActived {
          border: 1px solid @blue;
          border-right: none;

          &::after {
            background: @blue;
          }
        }

        .btn-container {
          position: absolute;
          right: -25px;
          top: 168px;
          width: 50px;
          height: 50px;
          line-height: 50px;
          text-align: center;
          cursor: pointer;
          color: #fff;
          background-color: @blue;
          border-radius: 200px;
          z-index: 3;
          font-size: 28px;

          &:hover {
            background: @blueHover;
          }
        }
      }

      .main_box_right {
        /*border: 1px solid #c2c6d0;*/
        /*border-left: none;*/
        /*border-radius: 0 3px 3px 0;*/

      }
    }

    .uploadTranslatePage {
      padding: 0px 10px 146px;
      margin-top: -20px;

      .uploadmain-box {
        max-width: 1200px;
        margin: 0 auto;
        min-height: 720px;
        background-color: #ffffff;
        box-shadow: 0px 1px 10px 0px rgba(36, 60, 94, 0.1);
        border-radius: 8px;
        position: relative;
        padding: 10px 0;
        box-sizing: border-box;

        .cancel {
          width: 90px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background-color: @blue;
          color: #fff;
          cursor: pointer;
          border-radius: 5px;
          position: absolute;
          right: 0;
          top: -50px;

          &:hover {
            box-shadow: @boxShadow;
          }
        }

        .leftBox,
        .rightBox {
          padding-left: 27px;
          padding-right: 20px;
          display: inline-block;
          overflow-x: auto;
        }

        .clear {
          clear: both;
        }

        .leftBox {
          width: 46%;
          float: left;
          min-height: 720px;
          // background-color: pink;
          .fileName {
            text-align: center;
            line-height: 720px;
            font-size: 16px;
            color: #414141;
          }
        }

        .rightBox {
          width: 45%;
          position: relative;
          min-height: 720px;
          float: left;
          // background-color: yellowgreen;
          .doc-loading {
            height: 60px;
            width: 447px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;

            .loading-outer {
              width: 100%;
              height: 8px;
              background-color: #dfdfdf;
              border-radius: 4px;

              .loading-inner {
                width: 140px;
                height: 8px;
                background-color: @blue;
                border-radius: 4px;
              }
            }

            .text {
              text-align: center;
              font-size: 14px;
              letter-spacing: 0px;
              color: #243c5e;
              margin-top: 38px;
            }
          }
        }

        &:after {
          content: "";
          height: 100%;
          width: 1px;
          background: #c7c7c7;
          position: absolute;
          left: 50%;
          top: 0;
        }

        &.translate_show {
          border-radius: 0 0 8px 8px;

          .translate_header {
            width: 100%;
            position: absolute;
            left: 0;
            top: -100px;

            .translate_btns {
              margin-bottom: 16px;

              .translate_back {
                display: inline-block;
                width: 40px;
                height: 32px;
                background-color: #ffffff;
                border-radius: 5px;
                border: solid 1px #d9d9d9;
                margin-right: 35px;
                cursor: pointer;
                line-height: 32px;
                text-align: center;
              }

              .translate_upload {
                display: inline-block;
                width: 100px;
                height: 32px;
                background-color: @blue;
                border-radius: 5px;
                line-height: 32px;
                font-size: 14px;
                text-align: center;

                &:hover {
                  box-shadow: @boxShadow;
                }
              }
            }

            .translate_title {
              background: #fff;
              display: flex;
              height: 50px;
              line-height: 50px;
              border-radius: 8px 8px 0 0;
              border-bottom: 1px solid #c7c7c7;

              li {
                flex: 1;
                text-align: left;
                padding-left: 28px;
                font-size: 16px;
                color: #323232;
              }
            }
          }
        }
      }
    }
  }
</style>
<style lang="less">
  .translate_popper.el-popper {
    border-color: @blue;
    background: #e1eeff;
    color: @blue;
    min-width: auto;
    padding: 6px 8px;
  }

  .translate_popper.el-popper[x-placement^="bottom"] .popper__arrow {
    border-bottom-color: @blue !important;
  }

  .translate_popper.el-popper[x-placement^="bottom"] .popper__arrow::after {
    border-bottom-color: #e1eeff !important; //箭头背景色
  }

  .time_confirm {
    .el-message-box__title {
      font-size: 16px;
      color: #777;
      letter-spacing: 2px;
    }

    .el-message-box__message p {
      font-size: 18px;
      color: #373a42;
      margin: 10px auto 45px;
      font-weight: bold;
      letter-spacing: 2px;
    }

    .el-message-box__btns button {
      width: 68px;
      height: 32px;
      line-height: 32px;
      padding: 0;
    }

    .el-message-box__btns button:nth-child(2) {
      margin-left: 50px;
      box-shadow: none;

      &:hover {
        box-shadow: @boxShadow;
      }
    }
  }
</style>
